import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { licensePaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import Collapsible from './Collapsible';
import * as G from './Grids';
import './styles.scss';
import { handleMoveElement } from '~/util/scrollutils';
import { IS_USER_INFO_AUTH } from '~/util/functionsutils';

export default function EditRequests({ history, location }) {
  const { data } = location.state;
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const [solicitationStatus, setSolicitatioStatus] = useState('');
  const [inputObservationValue, setInputObservationChange] = useState('');

  useEffect(() => {
    if (data) {
      const user = IS_USER_INFO_AUTH();

      if (data.status === 'Pendente' && user) {
        const template = `\n\nAtenciosamente,\n${user[0].nome}`;
        const regex = /Atenciosamente,\n.*/;

        setInputObservationChange(
          data.observacaoCancelamento
            ? data.observacaoCancelamento.replace(regex, template)
            : template
        );
      } else {
        setInputObservationChange(data.observacaoCancelamento);
      }

      setSolicitatioStatus(data.status);
    }
  }, [data]);

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanChangeStatusLicense = useMemo(
    () => userPermissions.includes(permissions.status_licenca),
    [userPermissions]
  );

  async function handleSave(id, isAproved = false) {
    try {
      setLoading(true);

      if (!inputObservationValue || !inputObservationValue.trim().length) {
        toast.error('Campo Observação de Cancelamento é obrigatório!');
        handleMoveElement('input-observacao-id');
        return;
      }

      const { data: resultData } = await api.post(
        isAproved
          ? endpoints.license.approveLicenseCancellation
          : endpoints.license.rejectLicenseCancellation,
        {
          id,
          observacaoCancelamento: inputObservationValue.trim(),
          dataEmail: {
            nomeCliente: data.nomeFantasiaCliente,
            cpfCnpjCliente: data.cpfCnpjCliente,
            cpfCnpjRevenda: data.cpfCnpjRevenda,
            tipo: data.tipo,
          }
        }
      );

      toast(
        `Solicitação ${isAproved ? 'aprovada' : 'rejeitada'} com sucesso!`,
        { type: toast.TYPE.SUCCESS }
      );

      if (resultData.data?.messages?.error) {
        const { error } = resultData.data?.messages;

        error.forEach((msg, index) => {
          if (typeof msg === 'string') {
            setTimeout(() => {
              toast(msg, { type: toast.TYPE.ERROR });
            }, 500 * (index + 1)); // 0.5s, 1s e 1.5s
          }
        });
      }

      setTimeout(() => {
        history.push(licensePaths.monitorRequests);
      }, 100);
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function isApprovedOrDenied() {
    return solicitationStatus === 'Aprovado' || solicitationStatus === 'Negado';
  }

  function handleCanChangeStatusLicense() {
    return !(userCanChangeStatusLicense && !isApprovedOrDenied());
  }

  return (
    <>
      <div>
        <Modal show={loading} loading />
        <SidebarPageHeader
          mainMenu="Licença"
          pageName="Editar Cancelamento"
          breadcrumbs={[{
            label: 'Monitorar Cancelamentos',
            link: licensePaths.monitorRequests
          }]}
          button1={{
            label: 'APROVADO',
            onClick: () => (() => handleSave(data.id, true)),
            show: handleCanChangeStatusLicense(),
            main: true,
          }}
          button2={{
            label: 'NEGADO',
            onClick: () => (() => handleSave(data.id, false)),
            show: handleCanChangeStatusLicense(),
            main: true,
          }}
          openButtonSelect
          buttonBackHandler={history.goBack}
        />
        {data && (
          <div className="editCancelationContainer" ref={componentRef}>
            <Collapsible
              disabled
              renderHeaderLeftComponent={() => <span>Dados do Solicitante</span>}
              renderHeaderRightComponent={() => (
                <span>
                  {format(parseISO(data.data), 'dd \'de\' MMM \'de\' yyyy', {
                    locale: pt,
                  })}
                </span>
              )}
              renderContent={() => (
                <G.CreatorGrid
                  creator={data?.criadoPor}
                />
              )}
            />

            <Collapsible
              renderHeaderLeftComponent={() => <span>Dados da Revenda</span>}
              renderContent={() => (
                <G.ResaleGrid
                  resale={{
                    nomeFantasia: data.nomeFantasiaRevenda,
                    cpfCnpj: data.cpfCnpjRevenda,
                  }}
                />
              )}
            />

            <Collapsible
              renderHeaderLeftComponent={() => <span>Dados do Cliente</span>}
              renderContent={() => (
                <G.ClientGrid
                  client={{
                    nomeFantasia: data.nomeFantasiaCliente,
                    cpfCnpj: data.cpfCnpjCliente,
                    telefone: data.telefoneCliente,
                    responsavelNome: data.responsavelNome,
                    responsavelTelefone1: data.responsavelTelefone1,
                    responsavelTelefone2: data.responsavelTelefone2,
                    responsavelEmail: data.responsavelEmail,
                  }}
                />
              )}
            />

            <Collapsible
              renderHeaderLeftComponent={() => <span>Dados da Licença</span>}
              renderContent={() => (
                <G.LicenseGrid
                  license={{
                    serie: data.serie,
                    tipo: data.tipo,
                    software: data.software,
                  }}
                />
              )}
            />

            {data.tipo === 'Total' && (
              <Collapsible
                renderHeaderLeftComponent={() => (
                  <span>Dados do Cancelamento</span>
                )}
                renderContent={() => (
                  <G.CancellationTotalGrid
                    cancellation={{
                      motivo: data.motivoCancelamento,
                      submotivo: data.submotivoCancelamento,
                      observacao: data.observacao,
                      numeroCaixas: data.numeroCaixas,
                      numeroUsuarios: data.numeroUsuarios,
                    }}
                  />
                )}
              />
            )}

            {data.tipo === 'Parcial' && (
              <Collapsible
                renderHeaderLeftComponent={() => (
                  <span>Módulos Cancelados</span>
                )}
                renderContent={() => (
                  <G.CancellationPartialGrid cancellation={data} />
                )}
              />
            )}

            <div id='input-observacao-id' />
            <Collapsible
              renderHeaderLeftComponent={() => (
                <span>Observação de Cancelamento *</span>
              )}
              renderHeaderRightComponent={() => (
                <span>
                  {isApprovedOrDenied() ? (
                    <>
                      {solicitationStatus} em {format(
                        parseISO(data.updatedAt),
                        'dd \'de\' MMM \'de\' yyyy',
                        { locale: pt }
                      )}
                    </>
                  ) : undefined}
                </span>
              )}
              renderContent={() => {
                let IS_DISABLED = userCanChangeStatusLicense;

                if (IS_DISABLED) {
                  IS_DISABLED = isApprovedOrDenied();
                } else {
                  IS_DISABLED = IS_DISABLED ? !isApprovedOrDenied() : true;
                }

                return (
                  <G.ObservationGrid
                    isDisabled={IS_DISABLED}
                    observationValue={inputObservationValue}
                    handleObservationChange={
                      (event) => setInputObservationChange(event.target.value)
                    }
                  />
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

EditRequests.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        nomeFantasiaRevenda: PropTypes.string,
        cpfCnpjRevenda: PropTypes.string,
        nomeFantasiaCliente: PropTypes.string,
        cpfCnpjCliente: PropTypes.string,
        telefoneCliente: PropTypes.string,
        serie: PropTypes.string,
        tipo: PropTypes.string,
        software: PropTypes.string,
        motivoCancelamento: PropTypes.oneOfType([
          PropTypes.string, PropTypes.object,
        ]),
        submotivoCancelamento: PropTypes.oneOfType([
          PropTypes.string, PropTypes.object,
        ]),
        status: PropTypes.string,
        observacao: PropTypes.string,
        data: PropTypes.string,
        updatedAt: PropTypes.string,
        observacaoCancelamento: PropTypes.string,
        numeroCaixas: PropTypes.oneOfType([
          PropTypes.number, PropTypes.object,
        ]),
        numeroUsuarios: PropTypes.oneOfType([
          PropTypes.number, PropTypes.object,
        ]),
        responsavelNome: PropTypes.string,
        responsavelTelefone1: PropTypes.string,
        responsavelTelefone2: PropTypes.string,
        responsavelEmail: PropTypes.string,
        criadoPor: PropTypes.any,
      }),
    }),
  }).isRequired,
};
